<template>
    <DadosBancarioList />
</template>

<script>
import DadosBancarioList from '../../components/cadastro/DadosBancarioList.vue';

export default {
    name: 'DadosBancario',
    components: { DadosBancarioList }
}
</script>