<template>
    <div class="card">
        <div class="flex align-items-center">
           <i class="pi pi-building" style="font-size: 1.4rem"></i> 
           <h3 style="margin:0px 5px;">Dados Bancários</h3>
        </div>

        <hr />

        <DataTable 
            responsiveLayout="stack"
            :value="data"
            v-model:selection="selecionado"
            selectionMode="single"
            showGridlines
            @sort="sort($event)">

            <template #header>
                <div class="flex align-items-center">
                    <div class="field mr-2" style="width: 15rem;">
                        <label for="estabelecimento">
                            <strong>Banco</strong>
                        </label>
                        <InputText v-model="filter.banco" class="w-full" />
                    </div>

                    <div class="field mr-2" style="width: 15rem;">
                        <label for="estabelecimento">
                            <strong>Agência</strong>
                        </label>
                        <InputText v-model="filter.agencia" class="w-full" />
                    </div>

                    <div class="field mr-2" style="width: 15rem;">
                        <label for="estabelecimento">
                            <strong>Conta</strong>
                        </label>
                        <InputText v-model="filter.conta" class="w-full" />
                    </div>

                    <div class="mr-2 mt-2">
                        <div class="p-inputgroup">
                            <Button
                                label="Filtrar"
                                icon="pi pi-filter"
                                class="p-button-outlined p-button-info"
                                @click="carregarDados()"
                            ></Button>

                            <Button 
                                title="Limpar Filtro"
                                icon="pi pi-filter-slash"
                                class="p-button-outlined p-button-info"
                                style="margin-left: 1px;"
                                @click="limparFiltro()"
                            ></Button>
                        </div>
                    </div>

                    <Button
                        v-if="filter.situacao == 'ATIVO'"
                        type="button"
                        icon="pi pi-ban"
                        label="Listar inativos"
                        class="p-button-outlined p-button-danger mr-2 mt-2"
                        @click="filter.situacao = 'INATIVO'; carregarDados()"
                    ></Button>

                    <Button
                        v-if="filter.situacao == 'INATIVO'"
                        type="button"
                        icon="pi pi-check-circle"
                        label="Listar ativos"
                        class="p-button-outlined mr-2 mt-2"
                        @click="filter.situacao = 'ATIVO'; carregarDados()"
                    ></Button>

                    <Button 
                        label="Cadastrar"
                        icon="pi pi-plus"
                        class="p-button-info mt-2"
                        @click="cadastrar()"
                    ></Button>
                </div>
            </template>

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column field="id" header="Id" style="width: 5rem;" />
            <Column field="banco" header="Banco" style="width: 25rem;" />
            <Column field="agencia" header="Agência" style="width: 8rem;" />
            <Column field="conta" header="Conta" style="width: 8rem;" />
            <Column field="cnpj" header="CNPJ" style="width: 10rem;"/>
            <Column header="Tipo Conta" style="width: 10rem;">
                <template #body="{ data }">
                    <span v-if="data.tipoConta">{{ data.tipoConta.descricao }}</span>
                </template>
            </Column>
            <Column header="Município" style="width: 10rem;">
                <template #body="{ data }">
                    <span v-if="data.municipio">{{ data.municipio.nome }}/{{ data.municipio.uf }}</span>
                </template>
            </Column>
            <Column field="ativo" header="Situação" style="width: 10rem;">
                <template #body="{ data, field }">
                    {{ data[field] == true ? 'Ativo' : 'Inativo' }}
                </template>
            </Column>
            <Column field="dataAtualizacao" header="Data Atualização" style="width: 10rem;">
                <template #body="{ data, field }">
                    {{ convertDateToString(data[field], "DD/MM/YYYY HH:mm") }}
                </template>
            </Column>
            <Column header="Ações" style="width:12rem;" class="centralizar-titulo-tabela">
                <template #body="{ data }">
                    <div class="flex-none">
                        <Button 
                            title="Visualizar histórico"
                            icon="pi pi-list"
                            class="p-button-rounded p-button-warning flex-none mr-2"
                            @click="visualizarHistorico(data)"
                        ></Button>

                        <Button
                            title="Editar"
                            icon="pi pi-pencil"
                            class="p-button-rounded p-button-info flex-none mr-2"
                            v-if="data.ativo"
                            @click="editar(data)"
                        ></Button>

                        <Button 
                            v-if="data.ativo"
                            title="Inativar"
                            class="p-button-rounded flex-none p-button-danger"
                            icon="pi pi-ban"
                            @click="confirmarAtivarOuInativar(data)"
                        ></Button>

                        <Button 
                            v-if="!data.ativo"
                            title="Ativar"
                            class="p-button-rounded flex-none p-button-info"
                            icon="pi pi-check-circle"
                            @click="confirmarAtivarOuInativar(data)"
                        ></Button>
                    </div>
                </template>
            </Column>
        </DataTable>

        <Paginator
            v-model:first="primeiraLinha"
            :rows="qtdRegistroPorPagina"
            :total-records="totalRegistro"
            :rowsPerPageOptions="[5, 10, 20, 30]"
            @page="onPage" />

    </div>

    <Dialog v-model:visible="ativarOuInativarDialog" :style="{width: '300px'}" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
            <span>Tem certeza que deseja <strong>{{ dados.ativo ? 'inativar' : 'ativar' }}</strong> os dados bancários: <strong>{{ dados.banco }}: {{ dados.agencia }} {{ dados.conta }}</strong>?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="ativarOuInativarDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="ativarOuInativar()" />
        </template>
    </Dialog>

    <DadosBancarioForm
        :dados="dados"
        :visivel="formularioDialog"
        @fechar="fecharFormulario"
    />

    <ModalHistoricoGenerico
        codigoPagina="DADOS_BANCARIO"
        :id="idRegistro"
        :descricao="descricaoHistoricoDialog"
        :visivel="historicoDialog"
        @fechar="ocultarHistorico()"
    />
</template>

<script>
import Formatacao from '../../utilities/Formatacao';
import DadosBancarioForm from './DadosBancarioForm.vue';
import StorageService from '../../service/StorageService';
import ModalHistoricoGenerico from '../ModalHistoricoGenerico.vue';
import DadosBancarioService from '../../service/DadosBancarioService';

export default {
    name: 'DadosBancarioList',
    components: { DadosBancarioForm, ModalHistoricoGenerico },
    data() {
        return {
            pagina: 0,
            primeiraLinha: 0,
            totalRegistro: 0,
            qtdRegistroPorPagina: 10,  

            data: [],

            filter: {},
            ordenar: 'id,asc',
            
            dados: {},
            selecionado: {},
            idRegistro: null,
            formularioDialog: false,
            ativarOuInativarDialog: false,
            descricaoHistoricoDialog: "",
            historicoDialog: false
        }
    },

    created() {
        const storange = StorageService.getControlePagina("DADOS-BANCARIOS");

        if(storange.default == undefined){
            this.filter = storange.filtros;
        }
        else {
            this.filter.situacao = "ATIVO";
        }

        this.pagina = storange.numero;
        this.primeiraLinha = storange.firstRow;
        this.qtdRegistroPorPagina = storange.qtdRegistro;
        this.carregarDados();
    },

    methods: {

        carregarDados() {
            this.filter["page"] = this.pagina;
            this.filter["size"] = this.qtdRegistroPorPagina;
            this.filter["sort"] = this.ordenar;

            DadosBancarioService.buscarComPaginacao(this.filter)
                .then(({ data }) => {
                    if(data) {
                        this.data = data.content;
                        this.qtdRegistroPorPagina = data.pageable.pageSize;
                        this.totalRegistro = data.totalElements;
                    }
                })
                .catch(error => {
                    const toast = {
                        severity:'error',
                        summary: 'Falha ao buscar dados bancários',
                        detail:`Falha não mapeada.`, 
                        life: 7500
                    };

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
                
            StorageService.setControlePaginaFilter("DADOS-BANCARIOS", this.pagina, this.primeiraLinha, this.qtdRegistroPorPagina, this.filter);               
        },

        confirmarAtivarOuInativar(contrato) {
            this.dados = contrato;
            this.ativarOuInativarDialog = true;
        },

        ativarOuInativar() {
            if(this.dados.ativo) {
                DadosBancarioService.inativar(this.dados.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Inativar',
                            detail:`Os dados bancários com ID ${ this.dados.id } foi inativado!`, 
                            life: 7500
                        });

                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.dados = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!',
                            detail: error, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["summary"] = "Falha ao inativar";
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }
            else {
                DadosBancarioService.ativar(this.dados.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Ativada',
                            detail:`Os dados bancários com ID ${ this.dados.id } foi ativado!`, 
                            life: 7500
                        });
                        
                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.dados = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!',
                            detail: error, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["summary"] = "Falha ao ativar.";
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }
        },

        onPage(event) {
            this.pagina = event.page + 1;
            this.primeiraLinha = event.rows * event.page;
            this.qtdRegistroPorPagina = event.rows; 
            this.carregarDados();
        },

        sort(event) {
            const sortField = event.sortField;
            const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.ordenar = `${ sortField },${sortOrder}`;
            this.carregarDados();
        },

        cadastrar() {
            this.dados = {};
            this.formularioDialog = true;
        },

        editar(dados) {
            this.formularioDialog = true;
            this.dados = dados;
        },

        fecharFormulario() {
            this.formularioDialog = false;
            this.dados = {};
            this.carregarDados();
        },

        limparFiltro() {
            this.filter["banco"] = undefined;
            this.filter["conta"] = undefined;
            this.filter["agencia"] = undefined;
            this.carregarDados();
        },

        visualizarHistorico(obj) {
            this.descricaoHistoricoDialog = `${ obj.banco }: ${ obj.agencia } ${ obj.conta }`;
            this.historicoDialog = true;
            this.idRegistro = obj.id.toString();
        },

        ocultarHistorico() {
            this.historicoDialog = false;
            this.idRegistro = null;
        },

        convertDateToString(date, format) {
            return Formatacao.convertDateToString(date, format);
        }
    }
}
</script>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>
