import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class DadosBancarioService {
    buscarComPaginacao(params = { situacao: 'ATIVO', page: 0, size: 10,  sort: 'id,asc' }) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/dados-bancario/buscar-com-paginacao`, { params });
    }

    salvar(data) {
        const config = {
            headers: { 'content-type' : 'application/json' }
        }
        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/dados-bancario`, data, config);
    }

    ativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/dados-bancario/ativar/${ id }`);
    }

    inativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/dados-bancario/inativar/${ id }`);
    }

    buscarPorId(id) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/dados-bancario/${ id }`);
    }

    listarTipoConta() {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/dados-bancario/listar/tipo-conta`);
    }
}

export default new DadosBancarioService();