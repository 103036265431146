<template>
    <Dialog v-model:visible="visivel" :style="{width: '600px', margin: '10%'}" :modal="true" @hide="fechar()">
        <template #header>
            <div class="flex align-items-center mt-2">
                <h4 style="margin:0;">{{ Object.keys(dados).length == 0 ? "Cadastrar" : "Editar" }} dados bancários</h4>
            </div> 
        </template>

        <Message v-if="mensagem.ativo" :severity="mensagem.gravidade" @close="mensagem.ativo = false">{{ mensagem.conteudo }}</Message>

        <div class="grid p-fluid formgrid">
            <div class="col-6">
                <div class="field">
                    <label for="banco">
                        <strong>Banco<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText id="banco" v-model="dados.banco" class="w-full" :class="{ 'p-invalid': campoInvalido.banco }" />
                </div>
            </div>

            <div class="col-6">
                <div class="field">
                    <label for="agencia">
                        <strong>Agência<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText id="agencia" v-model="dados.agencia" class="w-full" :class="{ 'p-invalid': campoInvalido.agencia }" />
                </div>
            </div>

            <div class="col-6">
                <div class="field">
                    <label for="conta">
                        <strong>Conta<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText id="conta" v-model="dados.conta" class="w-full" :class="{ 'p-invalid': campoInvalido.conta }" />
                </div>
            </div>

            <div class="col-6">
                <div class="field">
                    <label for="tipo_conta">
                        <strong>Tipo de conta<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <AutoComplete
                        placeholder="Selecione..." 
                        dropdown
                        v-model="dados.tipoConta"
                        field="descricao"
                        id="tipo_conta"
                        class="w-full"
                        :class="{ 'p-invalid': campoInvalido.tipoConta }"
                        :suggestions="tipoContaFiltro"
                        :forceSelection="true"
                        @complete="buscarTipoConta"
                    />
                </div>
            </div>

            <div class="col-6">
                <div class="field">
                    <label for="cidade">
                        <strong>Cidade<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <AutoComplete
                        placeholder="Selecione..." 
                        dropdown
                        v-model="dados.municipio"
                        field="label"
                        id="cidade"
                        class="w-full"
                        :class="{ 'p-invalid': campoInvalido.municipio }"
                        :suggestions="cidadesFiltro"
                        :forceSelection="true"
                        @complete="buscarMunicipio($event)"
                    />
                </div>
            </div>

            <div class="col-6">
                <div class="field">
                    <label for="cnpj_vendedor">
                        <strong>CNPJ do vendedor<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputMask 
                        id="cnpj_vendedor"
                        mask="99.999.999/9999-99"
                        placeholder="99.999.999/9999-99"
                        v-model="dados.cnpjVendedor"
                        :class="{ 'p-invalid': campoInvalido.cnpjVendedor }"
                    />
                </div>
            </div>
        </div>

        <template #footer>
            <div class="flex justify-content-center">
                <Button
                    icon="pi pi-save"
                    label="Salvar"
                    class="p-button-info ml-1"
                    @click="salvar()"
                ></Button>

                <Button
                    icon="pi pi-times"
                    label="Cancelar"
                    class="p-button-danger"
                    @click="fechar()"
                ></Button>
            </div>
        </template>
    </Dialog>
</template>

<script>
import DadosBancarioService from '../../service/DadosBancarioService';
import MunicipioService from '../../service/MunicipioService';

export default {
    name: "DadosBancariosForm",
    emits: ["fechar"],
    props: {
        visivel: { type: Boolean, required: true },
        dados: { type: Object, required: true }
    },
    watch: {
        dados:function(dados) {
            this.carregarTiposConta();
            if(!!dados.cnpj) this.dados.cnpjVendedor = dados.cnpj;
            if(!!dados.municipio) this.dados.municipio["label"] = `${ dados.municipio.nome } - ${ dados.municipio.uf }`;
        }
    },
    data() {
        return {
            mensagem: { ativo: false, gravidade: 'info', conteudo: '' },
            cidadesFiltro: [],
            tipoContas: [],
            tipoContaFiltro: [],
            campoInvalido: {}
        }
    },
    methods: {
        isCamposObrigatoriosPreenchidos() {
            const camposInvalido = [];

            if(!this.dados.banco) {
                this.campoInvalido.banco = true;
                camposInvalido.push("Banco");
            }
            else {
                this.campoInvalido.banco = false;
            }

            if(!this.dados.agencia) {
                this.campoInvalido.agencia = true;
                camposInvalido.push("Agência");
            }
            else {
                this.campoInvalido.agencia = false;
            }

            if(!this.dados.conta) {
                this.campoInvalido.conta = true;
                camposInvalido.push("Conta");
            }
            else {
                this.campoInvalido.conta = false;
            }

            if(!this.dados.tipoConta) {
                this.campoInvalido.tipoConta = true;
                camposInvalido.push("Tipo Conta");
            }
            else {
                this.campoInvalido.tipoConta = false;
            }

            if(!this.dados.municipio) {
                this.campoInvalido.municipio = true;
                camposInvalido.push("Cidade");
            }
            else {
                this.campoInvalido.municipio = false;
            }

            if(!this.dados.cnpjVendedor) {
                this.campoInvalido.cnpjVendedor = true;
                camposInvalido.push("CNPJ Vendedor");
            }
            else {
                this.campoInvalido.cnpjVendedor = false;
                this.dados.cnpj = this.dados.cnpjVendedor.replace(/\D/g, '');
            }

            if(camposInvalido.length > 0) {
                let mensagem = `Por favor, preencher o campo obrigatório: ${ camposInvalido.join(", ") }.`;

                if(camposInvalido.length > 1) {
                    mensagem = `Por favor, preencher os campos obrigatórios: ${ camposInvalido.join(", ") }.`;

                    const posicaoUltimaVirgula = mensagem.lastIndexOf(",");
                    if(posicaoUltimaVirgula > 0) {
                        mensagem = mensagem.substring(0, posicaoUltimaVirgula) + " e" + mensagem.substring(posicaoUltimaVirgula + 1);
                    }
                }

                this.mensagem = { ativo: true, gravidade: 'warn', conteudo: mensagem };
                return false;
            }

            return true;
        },

        limpar() {
            this.campoInvalido = {};
        },

        limparMensagem() {
            this.mensagem = { ativo: false };
        },

        salvar() {
            if(!this.isCamposObrigatoriosPreenchidos()) return;
            DadosBancarioService.salvar(this.dados)
                .then(({ data }) => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Dados bancários salvo.',
                        detail: `Os dados bancários ${ data.content.id } foi salvo com sucesso.`, 
                        life: 7500
                    });
                    this.fechar();
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!',
                        detail: error, 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["summary"] = "Não foi possível salvar.";
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        fechar() {
            this.$emit("fechar");
            this.limpar();
            this.limparMensagem();
        },

        carregarTiposConta() {
            DadosBancarioService.listarTipoConta()
                .then(({ data }) => {
                    this.tipoContas = data;
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!',
                        detail: error, 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["summary"] = "Não foi possível carregar os tipos de conta.";
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },
        
        buscarTipoConta(event) {
            if (!event.query.trim().length) {
                this.tipoContaFiltro = [...this.tipoContas];
            } else {
                this.tipoContaFiltro = this.tipoContas.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        buscarMunicipio(event) {
            const codigoOuDescricao = !event.query.trim().length ? 'a' : event.query;
            MunicipioService.buscarPorDescricao(codigoOuDescricao)
                .then(({ data }) => {
                    if(!!data && data.length > 0) {
                        this.cidadesFiltro = data.map(municipio => {
                            municipio["label"] = `${ municipio.nome } - ${ municipio.uf }`;
                            return municipio;
                        });
                    } else{
                        this.cidadesFiltro =[];
                    }
                })
                .catch(error => {
                    this.cidadesFiltro = [];
                });
        },
    }
}
</script>
